@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: Arial;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fond gris semi-transparent */
  z-index: 40; /* L'overlay doit être derrière le modal */
}
